<template>
  <div class="row">
    <div class="col-md-12 mb-2 boxFile" :class="{ 'uploading': loadingUpload }">
      <TextInput
        :hide-input="iValue"
        type="file"
        :label="label"
        accept="image/*"
        @changed="(file) => choosedFile(file)"
      />
      <div class="text-left">
        <img
          v-if="tempImage"
          :src="tempImage"
          style="height: 80px"
          :class="{ 'full-width': fullWidth }"
        />
        <div v-if="iValue" class="mt-2">
          <button
            class="btn btn-outline-danger btn-sm"
            :disabled="loadingRemove"
            @click="deleteFile('login_page_logo')"
          >
            <b-spinner v-if="loadingRemove" small />
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    resize: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: null,
    },

    label: {
      type: String,
      default: null,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },

    businessId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      loadingRemove: false,
      loadingUpload: false,
      tempImage: null,
    }
  },

  computed: {
    iValue: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      },
    },
  },

  mounted() {
    this.tempImage = this.value
  },

  methods: {
    choosedFile(file) {
      this.loadingUpload = true
      let uploadForm = new FormData()
      uploadForm.append('business_id', this.businessId)
      uploadForm.append('file', file)
      uploadForm.append('resize-image', this.resize)

      this.$store
        .dispatch('business/uploadImage', uploadForm)
        .then((res) => {
          this.iValue = res.path
          this.tempImage = res.path
          this.loadingUpload = false
        })
        .catch(() => {
          this.loadingUpload = false
        })

      let reader = new FileReader()

      reader.onload = () => {
        // this.tempImage = reader.result
      }

      reader.readAsDataURL(file)
    },

    deleteFile() {
      this.loadingRemove = true
      const params = {
        path: this.iValue,
        business_id: this.businessId
      }
      this.$store
        .dispatch('business/deleteImage', params)
        .then((res) => {
          this.loadingRemove = null
          this.iValue = null
          this.tempImage = null
        })
        .catch(() => {
          this.loadingRemove = null
        })
    },
  },
}
</script>

<style lang="scss">
.boxFile {
  .full-width {
    width: 100% !important;
    height: auto !important;
  }
  .input-group {
    background-color: #3b5998;
    border-color: 1px solid #3b5998;
    border-radius: 0.3rem;
    color: #fff;
    max-width: 180px;
    input[type='file'] {
      cursor: pointer;
    }
    &::before {
      position: absolute;
      top: 9px;
      left: calc(50% - 45px);
      font-weight: 600;
      color: #fff;
      pointer-events: none;
      content: 'Upload File';
    }
    &:hover {
      background-color: #30497c;
      border-color: #2d4373;
      &::before {
        color: #fff;
      }
    }
  }
  &.uploading {
    .input-group {
      opacity: .7;
      &::before {
        content: '';
        left: calc(50% - 0.75rem);
        width: 1.5rem;
        height: 1.5rem;
        vertical-align: text-bottom;
        border: 0.25em solid currentColor;
        border-right-color: transparent;
        border-radius: 50%;
        -webkit-animation: spinner-border 0.75s linear infinite;
        animation: spinner-border 0.75s linear infinite;
      }
    }
  }
  .form-control {
    opacity: 0;
  }
}
</style>
